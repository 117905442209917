<template>
    <div>
        <app-layout>
            <Header
                    @filter-div-status="datatable.filterStatus=$event">
            </Header>
            <HeaderMobile
                          @filter-div-status="datatable.filterStatus=$event">
            </HeaderMobile>
            <datatable-filter
                v-show="datatable.filterStatus"
                :exportExcel="true"
                @filter="filter"
                @filterClear="filterClear">
                <b-row>
                    <b-col sm="4">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.number"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                        :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-per-page-change="onPerPageChange"
                       :resultTitle="false"
                       v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import CommonModal from "@/components/elements/CommonModal";

// Services
import ProformaInvoiceService from "@/services/ProformaInvoiceService";

import qs from 'qs'

// Page
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import ProformaInvoice from "@/services/ProformaInvoiceService";

export default {
    components: {
        HeaderMobile,
        Header,
        AppLayout,

        DatatableFilter,
        Datatable,
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('download'),
                                permission:'proformainvoice_download',
                                class: 'ri-download-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.download(row)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                    },
                    {
                        label: this.toUpperCase('student_number'),
                        field: 'data.student_number',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'data.name',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'data.surname',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('created_at'),
                        field: 'created_at',
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    page: 1,
                    limit: 20
                }
            },
        }
    },
    created() {
        this.filterSet();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {number:null}
            this.getRows()
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return ProformaInvoiceService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                }).catch((err) => this.showErrors(err))
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        formClear() {
            this.formId = null;
            this.formProcess = null;
            this.Ids = [];
        },
        download(row){
            ProformaInvoice.download(row.id).then(response=>{
                this._downloadFile(response, row.data.student_number+'-'+row.created_at+'.pdf')
            })
        },
    }
}
</script>
